import { handlePopupClose } from '../../auto/utils/components/pop-up';
import { setDropdownInteration } from '../../auto/utils/components';
import equalHeights from '../../auto/utils/equalHeights';
import helpers from '../../auto/helpers';
import serviceConversionFactory from '../../auto/autoBoxOfficeServiceFactory';
import { createCookieUTM } from '../../auto/utils/utm';
import { initSectionWhenItIsVisible } from '../../auto/utils/initSectionWhenItIsVisible';
import hookConversion from '../../auto/utils/hookConversion';
import WhatsAppFormModal from '../../auto/components/WhatsAppFormModal';

createCookieUTM();

window.helpers = {
  ...helpers,
  initSectionWhenItIsVisible,
};
window.serviceConversion = serviceConversionFactory();
window.hookConversion = hookConversion;
window.components = {
  setDropdownInteration,
};
window.matchHeight = {
  match: equalHeights,
};

(() => {
  const components = {
    WhatsAppFormModal,
  };

  Object.keys(components).forEach(c => {
    window[c] = components[c];
  });
})();

handlePopupClose();
